@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

@font-face {
  font-family: "GilroyRegular";
  src: url("/assets/fonts/GilroyRegular.woff2") format("woff2"),
    url("/assets/fonts/GilroyRegular.woff") format("woff"),
    url("/assets/fonts/GilroyRegular.otf") format("otf");
  font-weight: 100;
}

@font-face {
  font-family: "GilroyBold";
  src: url("/assets/fonts/GilroyBold.woff2") format("woff2"),
    url("/assets/fonts/GilroyBold.woff") format("woff"),
    url("/assets/fonts/GilroyBold.otf") format("otf");
}

@font-face {
  font-family: "GilroyBlack";
  src: url("/assets/fonts/GilroyBlack.woff2") format("woff2"),
    url("/assets/fonts/GilroyBlack.woff") format("woff"),
    url("/assets/fonts/GilroyBlack.otf") format("otf");
}

* {
  font-family: GilroyRegular, Calibri, Helvetica, sans-serif;
}

$description: #343434;
$link: #0bb9b9;

.title {
  font-family: GilroyBold, Calibri, Helvetica, sans-serif;
  font-size: 2rem !important;
  color: $link !important;
}

.small-title {
  font-family: GilroyBold, Calibri, Helvetica, sans-serif;
  font-size: 1.3rem !important;
  color: $link !important;
}

.subtitle {
  font-family: GilroyBold, Calibri, Helvetica, sans-serif;
  font-size: 1.3rem;
}

.description {
  font-family: GilroyRegular, Calibri, Helvetica, sans-serif;
  font-size: 1rem;
  font-weight: 600;
  color: $description;
}

.small-text {
  color: var(--IRH_DarkGray, #979797);
  font-family: GilroyRegular;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 114.286% */
  letter-spacing: 0.4px;
}

.small-link {
  color: $link;
}

.link {
  font-family: GilroyBold, Calibri, Helvetica, sans-serif;
  font-size: 1rem;
  font-weight: 600;
  color: $link;
  text-decoration: underline;
}

.small-link {
  font-family: GilroyBold, Calibri, Helvetica, sans-serif;
  font-size: 1rem;
  font-weight: 600;
  color: $link;
  text-decoration: underline;
}

.small-text {
  font-family: GilroyBold, Calibri, Helvetica, sans-serif;
  font-size: 0.7rem;
  font-weight: 600;
  color: $link;
}

.input-label {
  font-family: GilroyRegular, Calibri, Helvetica, sans-serif;
  font-size: 1rem;
  font-weight: 600;
  color: rgb(77, 77, 77);
}

.toast-error {
  background: linear-gradient(to bottom, #b1104e, #4b0721) !important;
}

.toast-success {
  background: linear-gradient(to bottom, #15b110, #154b07) !important;
}

.toast-info {
  background: linear-gradient(to bottom, $link, rgb(7, 75, 75)) !important;
}

thead,
.thead {
  background: linear-gradient(
    to top,
    #0ed6d6 1%,
    #20e4e4 35.14%,
    #a9ffff 98.06%
  );
}

td {
  margin-top: 0 !important;
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.coupon-btn {
  position: absolute;
  width: 135px;
  top: 0;
  right: 5px;
}

.modal-container {
  overflow: scroll;
}

@media (max-width: 767px) {
  .main-section-container {
    width: 94%;
    height: auto;
    min-height: auto;
  }

  .coupon-btn {
    width: 90px;
  }
}

.hidden-bitrix {
  display: none !important;
}

.bitrix-btn {
  &.close-action {
    position: fixed;
    right: 50px;
    bottom: 90px;
    background-color: rgb(221, 72, 72);
    border-radius: 20px;
    color: white;
    height: 21px;
    width: 21px;
    z-index: 99999;
    border-color: white;
    border: 1px solid;
    padding: unset;
    img {
      content: url("/assets/images/svg/client/x-white.svg");
      width: 28px;
      height: 10px;
      vertical-align: initial;
      margin-left: 0px;
    }
  }
  &.show-action {
    position: fixed;
    right: -12px;
    bottom: 10px;
    background-color: #71e1e2;
    border-radius: 10px 0px 0px 10px;
    color: white;
    height: 46px;
    width: 48px;
    z-index: 99999;
    border-color: white;
    padding: 3px;
    img {
      content: url("/assets/images/svg/client/chat-white.svg");
      width: 38px;
      height: 19px;
      vertical-align: initial;
      margin-left: -5px;
    }
  }
}

.cdk-drop-list {
  overflow: scroll;
  height: 88vh;
  padding-left: 15px;
  padding-right: 6px;
}

.advanced-pie-legend .total-value {
  font-size: 28px !important; // Forzar el tamaño correcto.
} // Ajustar tamaño de texto para pantallas pequeñas.
